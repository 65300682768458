import React from 'react';
import { Tabs, Typography } from 'antd';
import { IUserDetails } from 'src/interfaces';
import ContactInformation from './ContactInformation';
import UpdatePassword from './UpdatePassword';
import ProfileImage from './ProfileImage';

const { TabPane } = Tabs;
const { Title } = Typography;

type SettingsCardProps = {
    isAdmin: boolean;
    manageUser?: boolean;
    customTitle?: React.ReactElement;
    userDetails: IUserDetails;
    userDetailsLoading?: boolean;
};

function SettingsCard({
    isAdmin,
    manageUser,
    customTitle,
    userDetails,
    userDetailsLoading,
}: SettingsCardProps): React.ReactElement {
    return (
        <>
            <div className="margin-bottom-lg">
                {customTitle ?? <Title level={3}>{isAdmin ? 'Administrator Settings' : 'Manage Account'}</Title>}
            </div>
            <div className="card-container">
                <Tabs defaultActiveKey="1" type="card">
                    <TabPane tab="Contact Information" key="contact-information">
                        <ContactInformation
                            isAdmin={isAdmin}
                            manageUser={manageUser}
                            userDetails={userDetails}
                            userId={userDetails?.id}
                            loading={userDetailsLoading}
                        />
                    </TabPane>
                    <TabPane tab="Profile Image" key="profile-image">
                        <ProfileImage
                            userId={userDetails?.id}
                            manageUser={manageUser}
                            userDetailsPhotoSrc={userDetails?.photo}
                        />
                    </TabPane>
                    <TabPane tab="Update Password" key="update-password">
                        <UpdatePassword userId={userDetails?.id} />
                    </TabPane>
                </Tabs>
            </div>
            <br />
        </>
    );
}

export default SettingsCard;
