import React from 'react';
import { Typography, Form, Input, Row } from 'antd';
import { useNavigate, Link } from 'react-router-dom';
import { RESET_PASSWORD } from 'src/graphql';
import { useMutation } from '@apollo/client';
import { errorMessage, successMessage } from 'src/utils/Notifications';
import { AuthImageHeader, PrimaryButton } from '../components';

const { Title, Text } = Typography;
const FormItem = Form.Item;

type FormValues = {
    email?: string;
};

function ForgotPassword(): React.ReactElement {
    const navigate = useNavigate();
    const [resetPasword, { loading }] = useMutation(RESET_PASSWORD);
    const handleResetPassword = (values: FormValues) => {
        const { email } = values;
        resetPasword({
            variables: {
                email,
            },
        })
            .then(() => {
                successMessage('Password reset secret key sent via EmailAddress');
                navigate('/');
            })
            .catch(() => {
                errorMessage('Password reset failed');
            });
    };
    return (
        <div className="webkit-center">
            <AuthImageHeader
                topText="Fusce Ultrices Ac Enim Nec Porttitor"
                pageTile="Forgot Password"
                breadCrumb="Account Forgot Password"
            />
            <div className="auth-form">
                <Form className="margin-y-xl" onFinish={handleResetPassword}>
                    <Row>
                        <Text style={{ fontWeight: 'bold' }} className="margin-bottom-md margin-left-sm float-left">
                            Please enter your email to receive your new password
                        </Text>
                    </Row>
                    <FormItem name="email">
                        <Input placeholder="Email" type="email" />
                    </FormItem>
                    <Row className="space-between">
                        <Link to="/">
                            <Title level={5} className="auth-link">
                                Back to login
                            </Title>
                        </Link>
                        <PrimaryButton title="Reset" htmlType="submit" loading={loading} />
                    </Row>
                </Form>
            </div>
        </div>
    );
}

export default ForgotPassword;
