/* eslint-disable react/prop-types,max-len,no-nested-ternary,no-param-reassign,no-unused-expressions */
import React, { useState } from 'react';
import { Upload, Typography } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';
import uploadIcon from '../assets/images/uploadIcon.svg';

const { Dragger } = Upload;
const { Text } = Typography;

interface UploadProps {
    text?: string;
    getUrl?: (url: string) => void;
    error?: string;
    userDetailsPhotoSrc?: string;
}

export default function UploadComponent(props: UploadProps): JSX.Element {
    const { text, getUrl, error, userDetailsPhotoSrc } = props;

    const [file, setFile] = useState<{ name: string } | undefined>(undefined);
    const [resFile, setResFile] = useState<string>('');

    const uploadProps = {
        name: 'file',
        multiple: false,
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        action: `${process.env.REACT_APP_UPLOAD_URL}`,
        onSuccess: (res: string[], uploadedFile: { name: string }) => {
            setFile(uploadedFile);
            getUrl?.(res[0]);
            setResFile(res[0]);
        },
        showUploadList: false,
    };

    return (
        <div className="margin-y-lg">
            <Dragger {...uploadProps}>
                {resFile.length > 0 ? (
                    <div
                        style={{
                            border: 'dotted lightgray',
                            padding: '0.5em',
                            borderRadius: '20px',
                            borderColor: 'rgba(1,1,1,0.2)',
                            width: '160px',
                        }}
                    >
                        <img
                            src={resFile}
                            style={{ height: '140px', width: '140px', borderRadius: '30px' }}
                            alt="upload"
                        />
                    </div>
                ) : (
                    <div
                        style={{
                            border: 'dotted lightgray',
                            padding: '0.5em',
                            borderRadius: '20px',
                            borderColor: 'rgba(1,1,1,0.2)',
                            width: '160px',
                        }}
                    >
                        <img
                            src={userDetailsPhotoSrc || uploadIcon}
                            alt="upload"
                            style={{ height: '140px', width: '140px', borderRadius: '30px' }}
                        />
                    </div>
                )}
            </Dragger>
            {file && Object.keys(file).length > 0 && (
                <div className="margin-bottom-md" style={{ color: 'gray', display: 'flex', justifyContent: 'center' }}>
                    Image uploaded successfully&nbsp;
                    <CheckCircleTwoTone style={{ marginTop: '4px' }} twoToneColor="#EF976E" />
                </div>
            )}
            {!!error && <Text type="danger">{error}</Text>}
            <div style={{ fontSize: '21px', textAlign: 'center', fontWeight: 'bold' }}>{text}</div>
        </div>
    );
}
