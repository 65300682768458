import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Typography, Popconfirm, Card } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { GET_USERS, DELETE_USER } from 'src/graphql';
import { useQuery, useMutation } from '@apollo/client';
import { IUserAccounts } from '../interfaces';
import { errorMessage, successMessage } from '../utils/Notifications';

const { Title } = Typography;

export type UserTableProps = {
    title?: string;
    roleId?: 'CLIENT' | 'ADMIN';
};

function UsersTable({ title, roleId }: UserTableProps): JSX.Element {
    const navigate = useNavigate();
    const [deleteUser] = useMutation(DELETE_USER);
    const [userAccounts, setUserAccounts] = useState<IUserAccounts[]>([]);
    const { loading: usersLoading, refetch } = useQuery(GET_USERS, {
        fetchPolicy: 'network-only',
        variables: { roleId },
        onCompleted: (data) => {
            setUserAccounts(data?.getUsers?.users as IUserAccounts[]);
        },
    });

    const handleEdit = (record: IUserAccounts) => {
        navigate(`profile/${record.id}`);
    };

    const handleDelete = (record: IUserAccounts) => {
        const userId = record.id;
        deleteUser({
            variables: {
                userId,
            },
        })
            .then(() => {
                refetch()
                    .then((res) => {
                        setUserAccounts(res.data?.getUsers?.users as IUserAccounts[]);
                        successMessage('User deleted successfully');
                    })
                    .catch(() => {
                        errorMessage('error fetching data');
                    });
            })
            .catch(() => {
                errorMessage('Failed to delete user');
            });
    };

    const columns = [
        {
            key: 'firstName',
            title: 'Name',
            dataIndex: 'firstName',
        },
        {
            key: 'email',
            title: 'Email',
            dataIndex: 'email',
        },
        {
            key: 'phone',
            title: 'Phone Number',
            dataIndex: 'phone',
        },
        {
            key: 'actions',
            title: 'Actions',
            render: (record: IUserAccounts) => (
                <div className="display-flex justify-center">
                    <div className="margin-x-xs">
                        <EditOutlined onClick={() => handleEdit(record)} className="hoverable-icon" />
                    </div>
                    <div>
                        <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record)}>
                            <DeleteOutlined className="hoverable-icon" />
                        </Popconfirm>
                    </div>
                </div>
            ),
        },
    ];

    return (
        <>
            <Title level={4}>{title}</Title>
            <Card className="card-no-padding" loading={usersLoading}>
                <Table bordered dataSource={userAccounts} columns={columns} rowKey={(record) => record.id} />
            </Card>
        </>
    );
}

export default UsersTable;
