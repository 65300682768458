import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { globalErrorMessageVariable } from 'src/graphql';
import { useReactiveVar } from '@apollo/client';
import { AuthLayout, MainLayout } from './pages';
import { ADMIN, deleteToken, getToken } from './services';
import { IAuth } from './interfaces';
import { errorMessage } from './utils/Notifications';

function App(): JSX.Element {
    const globalError: { message: string | null } = useReactiveVar(globalErrorMessageVariable);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const token: IAuth = getToken();

    useEffect(() => {
        if (Object.keys(token).length !== 0) {
            setIsAuthenticated(true);
            setIsAdmin((token as { user: { roleId: string } })?.user.roleId === ADMIN);
        }
    }, [token]);
    useEffect(() => {
        if (globalError.message) {
            console.log(globalError.message.trim());
            if (globalError.message.trim() === `jwt expired`) {
                errorMessage('Session Expired');
                deleteToken();
                window.location.href = '/';
            } else {
                errorMessage(globalError.message);
            }
        }
    }, [globalError, globalError.message]);
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/*"
                    element={
                        isAuthenticated && !token?.onBoarding ? (
                            <MainLayout setIsAuthenticated={setIsAuthenticated} isAdmin={isAdmin} />
                        ) : (
                            <AuthLayout setIsAuthenticated={setIsAuthenticated} setIsAdmin={setIsAdmin} />
                        )
                    }
                    key="home"
                />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
