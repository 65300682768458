import { notification, message } from 'antd';

type NotificationProps = {
    title: string;
    description: string;
    type: string;
};

const openNotification: (props: NotificationProps) => void = (props: NotificationProps) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    notification[props.type]({
        message: props.title,
        description: props.description,
        placement: 'bottomRight',
    });
};

const successMessage: (content: string) => void = (content: string) => {
    // eslint-disable-next-line no-void
    void message.success(content);
};
const errorMessage: (content: string) => void = (content: string) => {
    // eslint-disable-next-line no-void
    void message.error(content);
};
const warningMessage: (content: string) => void = (content: string) => {
    // eslint-disable-next-line no-void
    void message.warning(content);
};
const infoMessage: (content: string) => void = (content: string) => {
    // eslint-disable-next-line no-void
    void message.info(content);
};

export { openNotification, successMessage, errorMessage, warningMessage, infoMessage };
