import React, { memo } from 'react';
import { Col, Row } from 'antd';

interface EachMessageProps {
    isSelf: string;
    text: string;
    userName: string;
    userLastName: string;
}

function EachMessage(props: EachMessageProps): JSX.Element {
    const { isSelf, text, userName, userLastName } = props;
    const mode = isSelf === 'self' ? `self` : 'others';
    return (
        <div className={`each-message-${mode} `}>
            <Row
                className={`message-title padding-left-sm padding-${mode}`}
                style={{
                    textDecoration: 'underline',
                    textDecorationThickness: '3px',
                    textTransform: 'capitalize',
                    width: '100%',
                }}
            >
                {userName} {userLastName}
            </Row>
            <Row className={`message-description text-500 padding-left-sm  user-message ${mode}`}>{text}</Row>
        </div>
    );
}

export default memo(EachMessage);
