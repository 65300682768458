import React, { useEffect, useState } from 'react';
import { Typography, Form, Select, Input, Row } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { LeftCircleOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../components';
import { GET_USERS, SEND_MESSAGE, GET_CHANNELS } from '../graphql';
import { IUser } from '../interfaces';
import { successMessage } from '../utils/Notifications';
import { getID } from '../services';

const { Title, Text } = Typography;
const { Option } = Select;
const FormItem = Form.Item;

type FormData = {
    to: string;
    text: string;
};

function ComposeMessage(): JSX.Element {
    const navigate = useNavigate();
    const [users, setUsers] = useState<IUser[]>([]);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { loading: usersLoading, data } = useQuery(GET_USERS, {
        variables: {},
        fetchPolicy: 'network-only',
    });
    const [sendMessageMutation, { loading: sendingMessage }] = useMutation(SEND_MESSAGE);
    const [to, setTo] = useState<string>('');

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (data?.getUsers?.users)
            setUsers(
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                data.getUsers.users.filter((user: { id: string }) => user.id !== getID()),
            );
    }, [data]);

    const sendMessage = (formData: FormData) => {
        const { text } = formData;
        sendMessageMutation({
            variables: { to, text },
            refetchQueries: [{ query: GET_CHANNELS, variables: { count: 50, status: 'SENT' } }],
        })
            .then(() => {
                successMessage('Message sent successfully');
                navigate(-1);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div style={{ width: '90%' }}>
            <Row className="margin-bottom-lg space-between">
                <Title level={3}>Compose Message</Title>
                <Link to="/messages">
                    <div className="margin-top-xs margin-right-lg font-big">
                        <Text>
                            <LeftCircleOutlined />
                            &nbsp;Back
                        </Text>
                    </div>
                </Link>
            </Row>
            <Form onFinish={sendMessage}>
                <FormItem name="to">
                    <div style={{ width: '40%' }}>
                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                        {/* @ts-ignore */}
                        <Select loading={usersLoading} onChange={(val) => setTo(val)}>
                            {users?.map((user) => (
                                <Option value={(user as { id: string }).id} key={(user as { id: string }).id}>
                                    <>
                                        {user.firstName}&nbsp;{user.lastName}
                                    </>
                                </Option>
                            ))}
                        </Select>
                    </div>
                </FormItem>
                <FormItem name="text">
                    <Input.TextArea rows={12} />
                </FormItem>
                <div className="float-right">
                    <PrimaryButton title="Send" htmlType="submit" loading={sendingMessage} />
                </div>
            </Form>
        </div>
    );
}

export default ComposeMessage;
