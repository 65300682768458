import React from 'react';
import { Layout } from 'antd';
import { Route, Routes } from 'react-router-dom';
import { Footer, Header } from '../components';
import Login from './Login';
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';
import AccountOnboarding from './AccountOnboarding';
import { Authentication } from '../interfaces/Authentication';

const { Content } = Layout;

function AuthLayout({ setIsAuthenticated, setIsAdmin }: Authentication): React.ReactElement {
    return (
        <Layout>
            <Header isAuthenticated={false} />
            <Layout className="content-layout">
                <Content className="white-background">
                    <Routes>
                        <Route
                            key="login"
                            path="/*"
                            element={<Login setIsAuthenticated={setIsAuthenticated} setIsAdmin={setIsAdmin} />}
                        />
                        <Route
                            key="sign-up"
                            path="sign-up"
                            element={<SignUp setIsAuthenticated={setIsAuthenticated} setIsAdmin={setIsAdmin} />}
                        />
                        <Route
                            key="account-onboarding"
                            path="sign-up/account-onboarding"
                            element={<AccountOnboarding />}
                        />
                        <Route key="forgot-password" path="forgot-password" element={<ForgotPassword />} />
                    </Routes>
                </Content>
            </Layout>
            <Footer />
        </Layout>
    );
}

export default AuthLayout;
