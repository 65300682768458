/* eslint-disable @typescript-eslint/no-unsafe-assignment,react-hooks/exhaustive-deps */
import React from 'react';
import { Typography, Form, Input, Row } from 'antd';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { LOGIN } from 'src/graphql';
import { ADMIN, setToken } from 'src/services';
import { IAuth } from 'src/interfaces';
import { AuthImageHeader, PrimaryButton } from '../components';
import { Authentication } from '../interfaces/Authentication';

const { Title, Text } = Typography;
const { Password } = Input;
const FormItem = Form.Item;

type FormData = {
    email: string;
    password: string;
};

function Login({ setIsAuthenticated, setIsAdmin }: Authentication): React.ReactElement {
    const [loginMutation, { loading }] = useMutation(LOGIN);

    const handleLogin = (formData: FormData) => {
        const { email, password } = formData;
        loginMutation({
            variables: {
                credentials: {
                    email,
                    password,
                },
            },
        })
            .then((res) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                const token = res?.data?.login;
                setToken(token as IAuth);
                setIsAuthenticated(true);
                setIsAdmin((token as { user: { roleId: string } })?.user.roleId === ADMIN);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div className="webkit-center">
            <AuthImageHeader
                topText="Fusce Ultrices Ac Enim Nec Porttitor"
                pageTile="Account Login"
                breadCrumb="Account Login"
            />
            <div className="auth-form">
                <Form className="margin-y-xl" onFinish={handleLogin}>
                    <FormItem name="email" rules={[{ required: true, message: 'Email is required' }]}>
                        <Input placeholder="Email" type="email" />
                    </FormItem>
                    <FormItem name="password" rules={[{ required: true, message: 'Password is required' }]}>
                        <Password placeholder="Password" />
                    </FormItem>
                    <Row className="space-between">
                        <Link to="forgot-password">
                            <Title level={5} className="auth-link">
                                Forgot Password?
                            </Title>
                        </Link>
                        <PrimaryButton title="Login" htmlType="submit" loading={loading} />
                    </Row>
                </Form>
            </div>
        </div>
    );
}

export default Login;
