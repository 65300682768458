/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useLayoutEffect, useState, memo } from 'react';
import { Col, Row } from 'antd';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { getID } from 'src/services';
import { successMessage } from 'src/utils/Notifications';
import ScrollableFeed from 'react-scrollable-feed';
import userIcon from '../assets/images/userIcon.svg';
import EachMessage from './EachMessage';
import { GET_MESSAGES, SEND_MESSAGE } from '../graphql';
import { IMessages } from '../interfaces';
import { PrimaryButton } from './Buttons';

interface MessagesListProps {
    channelId: string;
    othersId: string;
    othersUserPhoto?: string;
    othersFirstName: string;
    othersLastname: string;
}

function MessageList(props: MessagesListProps): JSX.Element {
    const { channelId, othersId, othersUserPhoto, othersFirstName, othersLastname } = props;
    const [oneOnOneMessage, setOneOnOneMessage] = useState<any>();
    const [getMessages, { data: getMessagesData }] = useLazyQuery(GET_MESSAGES, {
        variables: { status: 'SENT', otherUserId: othersId },
    });
    const { startPolling, stopPolling } = useQuery(GET_MESSAGES, {
        variables: { status: 'SENT', otherUserId: othersId },
        fetchPolicy: 'network-only',
    });

    const [sendMessage] = useMutation(SEND_MESSAGE, {
        refetchQueries: [GET_MESSAGES, 'getMessages'],
    });

    const [newMesssage, setNewMesssage] = useState('');
    useEffect(() => {
        try {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            getMessages();
        } catch (err) {
            console.log('Error catch :', err);
        }
    }, [channelId]);

    useLayoutEffect(() => {
        startPolling(5000);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        getMessages();
        return () => {
            stopPolling();
        };
    }, [othersId]);
    useEffect(() => {
        if (getMessagesData) {
            console.log('getMessagesData RESPONSE', getMessagesData);
            setOneOnOneMessage(getMessagesData.getMessages);
        }
    }, [getMessagesData]);
    const checkSenderIfSelf = (message: IMessages) => {
        const currentUser = getID();
        if (message?.from?.id === currentUser) {
            return true;
        }
        return false;
    };
    const handleSubmitMessage = () => {
        sendMessage({
            variables: {
                text: newMesssage,
                to: othersId,
            },
        })
            .then(() => {
                // eslint-disable-next-line no-void
                try {
                    setNewMesssage('');
                    // eslint-disable-next-line no-void
                    // void getMessages();
                } catch (error) {
                    console.log('while error in getting', getMessages);
                }
                successMessage('Message sent successfully');
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <>
            <div className="msg-area">
                <ScrollableFeed className="scrollable_style">
                    {/* eslint-disable-next-line @typescript-eslint/no-unsafe-call */}
                    {oneOnOneMessage &&
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                        oneOnOneMessage.map((msg: any): JSX.Element => {
                            return !checkSenderIfSelf(msg) ? (
                                <Row className="selectable" style={{ width: '100%' }}>
                                    <Col className="align-self-baseline padding-y-sm">
                                        {othersUserPhoto ? (
                                            <img src={othersUserPhoto} alt="" className="large-icon" />
                                        ) : (
                                            <img src={userIcon} alt="" className="large-icon" />
                                        )}
                                    </Col>
                                    <Col className="padding-left-md " span={20} style={{ width: '100%' }}>
                                        <Row className="" style={{ width: '100%' }}>
                                            <EachMessage
                                                isSelf="other"
                                                text={msg.text}
                                                userName={othersFirstName}
                                                userLastName={othersLastname}
                                            />
                                        </Row>
                                    </Col>
                                </Row>
                            ) : (
                                <Row className="selectable sender" style={{ width: '100%' }}>
                                    <Col className="align-self-baseline padding-y-sm">
                                        {msg?.from?.photo ? (
                                            <img src={msg?.from?.photo} alt="" className="large-icon" />
                                        ) : (
                                            <img src={userIcon} alt="" className="large-icon" />
                                        )}
                                    </Col>
                                    <Col
                                        className="padding-right-md sender_message "
                                        span={20}
                                        style={{ width: '100%' }}
                                    >
                                        <Row className="sender_align">
                                            <EachMessage
                                                text={msg.text}
                                                isSelf="self"
                                                userName={msg?.from?.firstName}
                                                userLastName={msg?.from?.lastName}
                                            />
                                        </Row>
                                    </Col>
                                </Row>
                            );
                        })}
                </ScrollableFeed>
            </div>

            <Row className="send__new_msg_outer" style={{ width: '100%' }}>
                <div className="send__new_msg">
                    <input
                        type="text"
                        className="send__new_input"
                        value={newMesssage}
                        onChange={(e) => setNewMesssage(e.target.value)}
                    />
                    <div className="send_btn">
                        <PrimaryButton
                            title="Send"
                            styles={{ borderRadius: 0, marginBlock: 'auto' }}
                            onClick={() => {
                                handleSubmitMessage();
                            }}
                        />
                    </div>
                </div>
            </Row>
        </>
    );
}

export default memo(MessageList);
