/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { IAuth } from 'src/interfaces';
import { User } from './Constants';

export const getToken = (): IAuth => JSON.parse(localStorage.getItem(User) || '{}');
export const setToken = (user: IAuth) => localStorage.setItem(User, JSON.stringify(user));
export const deleteToken = () => localStorage.removeItem(User);

// eslint-disable-next-line consistent-return
export const getID = () => {
    const token: IAuth = getToken();
    if (Object.keys(token).length !== 0) {
        const id = token?.user?.id;
        return id;
    }
    return null;
};
