import React from 'react';
import { Layout, Col, Row, Typography } from 'antd';
import { InstagramOutlined, FacebookFilled, LinkedinFilled } from '@ant-design/icons';
import logo from '../assets/images/whiteLogo.svg';

const { Title, Text } = Typography;

function Footer(): JSX.Element {
    return (
        <Layout.Footer>
            <Row className="dark-bg align-self-center padding-xl footer-row">
                <Col span={6}>
                    <Row>
                        <img src={logo} alt="" style={{ height: '80px' }} />
                    </Row>
                    <Row className="margin-top-xl margin-bottom-md">
                        <Text className="footer-text">ReveNewCycle’s vision is to financially stabilize the</Text>
                        <Text className="footer-text">revenue of healthcare providers that need it the most</Text>
                    </Row>
                </Col>
                <Col span={11} />
                {/* No Menu in Footer 
                <Col span={3} className="align-self-center">
                    <Row className="margin-top-xl margin-bottom-md">
                        <Text className="footer-menu">Home</Text>
                    </Row>
                    <Row className="margin-top-xl margin-bottom-md">
                        <Text className="footer-menu">About</Text>
                    </Row>
                </Col>
                <Col span={3} className="align-self-center">
                    <Row className="margin-top-xl margin-bottom-md">
                        <Text className="footer-menu">Services</Text>
                    </Row>
                    <Row className="margin-top-xl margin-bottom-md">
                        <Text className="footer-menu">Portfolio</Text>
                    </Row>
                </Col>
                <Col span={3} className="align-self-center">
                    <Row className="margin-top-xl margin-bottom-md">
                        <Text className="footer-menu">Request A Quote</Text>
                    </Row>
                    <Row className="margin-top-xl margin-bottom-md">
                        <Text className="footer-menu">Contact</Text>
                    </Row>
                </Col> */}
                <Col span={2} />
                <Col span={5} className="align-self-center">
                    <Row className="margin-bottom-md">
                        <Text className="footer-menu">Follow Us:</Text>
                    </Row>
                    <Row>
                        <a href="https://www.instagram.com/revenewcycle/">
                            <InstagramOutlined className="footer-icon" />
                        </a>
                        <a href="https://www.facebook.com/revenewcycle/">
                            <FacebookFilled className="footer-icon" />
                        </a>
                        <a href="https://www.linkedin.com/in/revenewcyclemgmt/">
                            <LinkedinFilled className="footer-icon" />
                        </a>
                    </Row>
                </Col>
            </Row>
            <Row className="dark-bg align-self-center padding-xl">
                <Col span={8}>
                    <Text className="footer-text">Copyright &#169; 2021 RevenewCycle. All Rights Reserved.</Text>
                </Col>
                <Col span={10} />
                <Col span={6}>
                    <Row className="float-right">
                        <Text className="footer-text margin-right-lg">Privacy Policy</Text>
                        <Text className="footer-text">Terms & Conditions</Text>
                    </Row>
                </Col>
            </Row>
        </Layout.Footer>
    );
}

export default Footer;
