/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { AuthImageHeader, PrimaryButton, DocsOnboardCard, SecondaryButton } from '../components';
import { IDocuments } from '../interfaces';
import { GET_CLIENT_DOCUMENTS } from '../graphql';
import { getToken, setToken } from '../services';

function AccountOnboarding(/* {}: AccountOnboardingProps */): React.ReactElement {
    const navigate = useNavigate();
    const { user, onBoarding } = getToken();
    const [documents, setDocuments] = useState<IDocuments[]>([]);
    const [uploading, setUploading] = useState<boolean>(false);

    const { data: documentsData } = useQuery(GET_CLIENT_DOCUMENTS, {
        variables: { clientId: getToken()?.user?.id },
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-argument
        if (documentsData?.getClientDocuments) setDocuments(documentsData.getClientDocuments);
    }, [documentsData]);

    useEffect(() => {
        if (!onBoarding) navigate('/');
    }, [onBoarding]);

    const handleSubmit = () => {
        const token = getToken();
        setToken({ ...token, onBoarding: false });
        navigate('/');
        navigate(0);
    };

    return (
        <div className="webkit-center">
            <AuthImageHeader
                topText="Fusce Ultrices Ac Enim Nec Porttitor"
                pageTile="Account Onboarding"
                breadCrumb="Account Creation"
            />
            <h1 className="margin-top-lg" style={{ fontSize: '28px', color: '#485460' }}>
                Welcome to ReveNewCycle Management, {user?.firstName}
            </h1>
            <p style={{ fontSize: '14px', fontWeight: 'bold', width: '50%' }}>
                As part of our customer onboarding, we require the following documents, forms and files upload to be
                completed in order to provide full services from ReveNewCycle Management
            </p>
            <br />
            <DocsOnboardCard documents={documents} getUploading={setUploading} />
            <Row className="justify-center margin-top-xl">
                <div className="margin-right-sm">
                    <SecondaryButton title="SKIP" onClick={handleSubmit} />
                </div>
                <div className="margin-left-sm">
                    <PrimaryButton title="COMPLETE" onClick={handleSubmit} disabled={uploading} />
                </div>
            </Row>
        </div>
    );
}

export default AccountOnboarding;
