import React, { createContext, useState } from 'react';
import { IUser } from 'src/interfaces';
import { getToken } from 'src/services';

type IUserContext = {
    userState: IUser;
    setUserState: React.Dispatch<React.SetStateAction<IUser>>;
};
type UserContextProviderProps = {
    children: React.ReactNode;
};
export const UserContext = createContext({} as IUserContext);
export const UserContextProvider = ({ children }: UserContextProviderProps) => {
    const [userState, setUserState] = useState(getToken().user as IUser);
    return <UserContext.Provider value={{ userState, setUserState }}>{children}</UserContext.Provider>;
};
