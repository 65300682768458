import { gql } from '@apollo/client';

const GET_CHANNELS = gql`
    query getChannels($count: Float, $offset: Float, $status: MESSAGE_STATUS!) {
        getChannels(count: $count, offset: $offset, status: $status) {
            id
            text
            to {
                id
                firstName
                lastName
                email
                phone
                roleId
                photo
            }
            from {
                id
                firstName
                lastName
                email
                phone
                roleId
                photo
            }
            createdAt
            updatedAt
            status
        }
    }
`;

const GET_MESSAGES = gql`
    query getMessages($count: Float, $offset: Float, $status: MESSAGE_STATUS!, $otherUserId: ID!) {
        getMessages(count: $count, offset: $offset, status: $status, otherUserId: $otherUserId) {
            id
            text
            to {
                id
                firstName
                lastName
                email
                phone
                roleId
                photo
            }
            from {
                id
                firstName
                lastName
                email
                phone
                roleId
                photo
            }
            createdAt
            updatedAt
            status
        }
    }
`;

const GET_USERS = gql`
    query getUsers($count: Float, $offset: Float, $roleId: USER_ROLE) {
        getUsers(count: $count, offset: $offset, roleId: $roleId) {
            users {
                id
                firstName
                lastName
                email
                phone
                roleId
                photo
            }
            pagination {
                total
                page
                count
            }
        }
    }
`;

const GET_CLIENT_DOCUMENTS = gql`
    query getClientDocuments($clientId: ID!) {
        getClientDocuments(clientId: $clientId) {
            id
            type
            dueBy
            receivedOn
            url
            status
        }
    }
`;

export const GET_USER_DETAILS = gql`
    query getUserDetails($userId: ID!) {
        getUserDetails(userId: $userId) {
            id
            firstName
            lastName
            email
            phone
            fax
            photo
            address
            city
            state
            zip
            status
            roleId
        }
    }
`;

export { GET_CHANNELS, GET_MESSAGES, GET_USERS, GET_CLIENT_DOCUMENTS };
