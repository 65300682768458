/* eslint import/prefer-default-export: "off" */

import { makeVar } from '@apollo/client';

type LoggedInUserType = {
    firstName: string;
    lastName: string;
    picture?: string;
    token: string;
};

const LoggedInUser = makeVar<LoggedInUserType | undefined>(undefined);
const selectedMessage = makeVar<string>('');
const globalErrorMessageVariable = makeVar<{ message: string | null }>({ message: null });

export { LoggedInUser, globalErrorMessageVariable, selectedMessage };
