/* eslint-disable @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment,react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { GET_USER_DETAILS } from 'src/graphql';
import { useLazyQuery } from '@apollo/client';
import { IUserDetails } from 'src/interfaces';
import { getToken } from 'src/services';
import SettingsCard from './SettingsCard';

type UserSettingsProps = {
    isAdmin: boolean;
};

function Settings({ isAdmin }: UserSettingsProps): React.ReactElement {
    const userId = getToken().user?.id;
    const [userDetails, setUserDetails] = useState<IUserDetails>({});
    const [getUserDetails, { loading: userDetailsLoading, data: userDetailsData }] = useLazyQuery(GET_USER_DETAILS, {
        variables: { userId },
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        getUserDetails();
    }, [userId]);

    useEffect(() => {
        if (userDetailsData?.getUserDetails) {
            setUserDetails(userDetailsData.getUserDetails);
        }
    }, [userDetailsData]);

    return <SettingsCard isAdmin={isAdmin} userDetailsLoading={userDetailsLoading} userDetails={userDetails} />;
}

export default Settings;
