import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { getToken } from 'src/services';
import { onError } from '@apollo/client/link/error';
import { globalErrorMessageVariable } from './variables';

const errorLink = onError(({ graphQLErrors, networkError }) => {
    let messageVar: string | null = null;
    if (graphQLErrors) {
        messageVar = graphQLErrors.map(({ message }) => `${message}`).join(', ');
    }
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    if (networkError) messageVar = `Network Error ${networkError}`;
    const objMessage = messageVar;
    const multiErrors = objMessage?.split(',').find((currentValue) => currentValue.includes('detail'));
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    globalErrorMessageVariable({ message: multiErrors || messageVar });
});
// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
const httpLink = new HttpLink({ uri: `${process.env.REACT_APP_GRAPHQL_SERVER_URL}` });
const authMiddlewareLink = new ApolloLink(
    (
        operation: { setContext: (arg0: { headers: { authorization: string | null } }) => void },
        forward: (arg0: any) => any,
    ) => {
        const user = getToken();
        let userToken;
        if (Object.keys(user).length !== 0) {
            if (user?.token) {
                userToken = `Bearer ${user?.token}`;
            }
        }
        operation.setContext({
            headers: {
                authorization: userToken || null,
            },
        });
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return forward(operation);
    },
);

const link = ApolloLink.from([errorLink, authMiddlewareLink, httpLink]);

const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
});

export default client;
