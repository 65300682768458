/* eslint-disable  @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment */
import React, { useEffect, useContext } from 'react';
import { Form, Select, Input, Card, Row, Col } from 'antd';
import { IAuth, IUser, IUserDetails } from 'src/interfaces';
import { UPDATE_USER } from 'src/graphql';
import { useMutation } from '@apollo/client';
import { errorMessage, successMessage } from 'src/utils/Notifications';
import { UserContext } from 'src/utils/UserContext';
import { getToken, setToken } from 'src/services';
import { PrimaryButton } from '../components';

const FormItem = Form.Item;

type ContactInformationProps = {
    isAdmin: boolean;
    manageUser?: boolean;
    userDetails: IUserDetails;
    userId?: string;
    loading?: boolean;
};

function ContactInformation({
    isAdmin,
    manageUser,
    userDetails,
    userId,
    loading,
}: ContactInformationProps): JSX.Element {
    const [updateUser, { loading: updatingUser }] = useMutation(UPDATE_USER);
    const userContext = useContext(UserContext);
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(userDetails);
    }, [userDetails]);

    const handleUpdate = (formData: IUserDetails) => {
        const { firstName, lastName, email, phone, fax, address, city, state, zip } = formData;
        updateUser({
            variables: {
                update: {
                    firstName,
                    lastName,
                    email,
                    phone,
                    fax,
                    address,
                    city,
                    state,
                    zip,
                },
                userId,
            },
        })
            .then(() => {
                successMessage('User details updated successfully');
                if (!manageUser) {
                    const data = getToken();
                    const newData: IAuth = {
                        ...data,
                        user: {
                            ...data.user,
                            firstName,
                            lastName,
                            email,
                            phone,
                        } as IUser,
                    };
                    setToken(newData);
                    userContext.setUserState(newData.user as IUser);
                }
            })
            .catch(() => {
                errorMessage('Failed to update user');
            });
    };

    return (
        <div className="bordered-card">
            <Card className="card padding-x-xl" loading={loading}>
                <Form className="padding-top-lg" onFinish={handleUpdate} form={form}>
                    <Row>
                        <Col span={12} className="padding-right-sm">
                            <FormItem name="firstName">
                                <Input placeholder="First Name" />
                            </FormItem>
                        </Col>
                        <Col span={12} className="padding-left-sm">
                            <FormItem name="lastName">
                                <Input placeholder="Last Name" />
                            </FormItem>
                        </Col>
                    </Row>
                    <FormItem
                        name="email"
                        rules={[
                            { required: true, message: 'Email is required' },
                            { type: 'email', message: 'The input is not valid E-mail!' },
                        ]}
                    >
                        <Input placeholder="Email" type="email" />
                    </FormItem>
                    <Row>
                        <Col span={12} className="padding-right-sm">
                            <FormItem name="phone">
                                <Input placeholder="Phone Number" />
                            </FormItem>
                        </Col>
                        <Col span={12} className="padding-left-sm">
                            <FormItem name="fax">
                                <Input placeholder="Fax Number" />
                            </FormItem>
                        </Col>
                    </Row>
                    {(!isAdmin || manageUser) && (
                        <>
                            <FormItem name="address">
                                <Input placeholder="Office Address" />
                            </FormItem>
                            <Row>
                                <Col span={9} className="padding-right-sm">
                                    <FormItem name="city">
                                        <Input placeholder="City" />
                                    </FormItem>
                                </Col>
                                <Col span={6} className="padding-x-sm">
                                    <FormItem name="state">
                                        <Select placeholder="State" />
                                    </FormItem>
                                </Col>
                                <Col span={9} className="padding-left-sm">
                                    <FormItem name="zip">
                                        <Input placeholder="Zip Code" />
                                    </FormItem>
                                </Col>
                            </Row>
                        </>
                    )}
                    <div className="float-right margin-bottom-lg">
                        <PrimaryButton title="Save" htmlType="submit" loading={updatingUser} />
                    </div>
                </Form>
            </Card>
        </div>
    );
}

export default ContactInformation;
