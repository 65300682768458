import React from 'react';
import { Typography, Row, Input, Col, Select, Form } from 'antd';
import { INVITE_USER } from 'src/graphql';
import { useMutation } from '@apollo/client';
import { errorMessage, successMessage } from 'src/utils/Notifications';
import { PrimaryButton, UsersTable } from '../components';
import { ADMIN, CLIENT } from '../services';

const { Title } = Typography;
const FormItem = Form.Item;
const { Option } = Select;

type FormValues = {
    email: string;
    selectUser: 'ADMIN' | 'CLIENT';
};

function Accounts(): React.ReactElement {
    const [inviteUser, { loading: invitingUser }] = useMutation(INVITE_USER);
    const handleInvite = (values: FormValues) => {
        const { email, selectUser } = values;
        inviteUser({
            variables: {
                email,
                role: selectUser,
            },
        })
            .then(() => {
                successMessage('User invited successfully');
            })
            .catch(() => {
                errorMessage('Failed to invite user');
            });
    };
    return (
        <div>
            <div className="restricted-width margin-bottom-xl">
                <Title level={4}>Invite New User</Title>
                <Row>
                    <Form className="margin-y-xl" onFinish={handleInvite} layout="inline">
                        <Col sm={24} lg={10} xl={10} className="padding-right-sm">
                            <FormItem
                                name="email"
                                rules={[
                                    { required: true, message: 'Email is required' },
                                    { type: 'email', message: 'The input is not valid E-mail!' },
                                ]}
                            >
                                <Input placeholder="Email" type="email" />
                            </FormItem>
                        </Col>
                        <Col sm={12} lg={8} xl={8} className="padding-left-sm padding-top-sm">
                            <FormItem name="selectUser">
                                <Select className="full-width" placeholder="Select user">
                                    <Option value="ADMIN">Admin</Option>
                                    <Option value="CLIENT">Client</Option>
                                </Select>
                            </FormItem>
                        </Col>
                        <Col sm={12} lg={4} xl={4} className="padding-left-lg align-self-center">
                            <PrimaryButton title="Invite" htmlType="submit" loading={invitingUser} />
                        </Col>
                    </Form>
                </Row>
            </div>
            <UsersTable roleId={CLIENT} title="Current User Accounts" />
            <br />
            <UsersTable roleId={ADMIN} title="Administrative Accounts" />
        </div>
    );
}

export default Accounts;
