import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from 'antd';
import { GET_CLIENT_DOCUMENTS, GET_USER_DETAILS } from 'src/graphql';
import { useLazyQuery } from '@apollo/client';
import { IDocuments, IUserDetails } from 'src/interfaces';
import { DocumentsCard } from '../components';
import SettingsCard from './SettingsCard';

const { Title } = Typography;

export type ProfileProps = {
    isAdmin: boolean;
};

function Profile({ isAdmin }: ProfileProps): React.ReactElement {
    const { userId } = useParams();
    const [userDetails, setUserDetails] = useState<IUserDetails>({});
    const [userDocuments, setUserDocuments] = useState<IDocuments[]>([]);
    const [getUserDetails, { loading: userDetailsLoading, data: userDetailsData }] = useLazyQuery(GET_USER_DETAILS, {
        variables: { userId },
        fetchPolicy: 'network-only',
    });
    const [getDocuments, { loading: documentsLoading, data: documentsData }] = useLazyQuery(GET_CLIENT_DOCUMENTS, {
        variables: { clientId: userId },
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        getUserDetails();
        getDocuments();
    }, [userId]);

    useEffect(() => {
        if (userDetailsData?.getUserDetails) {
            setUserDetails(userDetailsData.getUserDetails);
        }
        if (documentsData?.getClientDocuments) {
            setUserDocuments(documentsData.getClientDocuments);
        }
    }, [userDetailsData, documentsData]);

    return (
        <>
            <SettingsCard
                manageUser
                isAdmin={isAdmin}
                userDetails={userDetails}
                userDetailsLoading={userDetailsLoading}
                customTitle={
                    <>
                        <Title level={3}>User: {userDetails.firstName}</Title>
                        <Title level={5}>Manage Account</Title>
                    </>
                }
            />
            <br />
            <DocumentsCard documents={userDocuments} loading={documentsLoading} isAdmin userId={userId} />
        </>
    );
}

export default Profile;
