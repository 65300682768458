/* eslint-disable @typescript-eslint/no-unsafe-call, no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Col, Row, Collapse, Typography, Select, DatePicker, Form } from 'antd';
import { startCase } from 'lodash';
import { RightCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { IDocuments } from '../interfaces';
import UploadDoc from './UploadDoc';
import { COMPLETE, REQUIRED } from '../services';
import { PrimaryButton } from './Buttons';
import { GET_CLIENT_DOCUMENTS, UPDATE_DOCUMENT_STATUS } from '../graphql';
import { successMessage } from '../utils/Notifications';

const { Panel } = Collapse;
const { Text } = Typography;
const { Option } = Select;

interface DocsOnboardCardProps {
    documents: IDocuments[];
    getUploading?: (uploading: boolean) => void;
    isAdmin?: boolean;
    userId?: string;
}

function DocsOnboardCard(props: DocsOnboardCardProps): JSX.Element {
    const { documents, getUploading, isAdmin, userId } = props;
    const [selectedPanels, setSelectedPanels] = useState<string | string[]>([]);
    const [uploadDocumentStatusMutation] = useMutation(UPDATE_DOCUMENT_STATUS);
    const [updatingDoc, setUpdatingDoc] = useState<string>('');

    useEffect(() => {
        setSelectedPanels(documents?.map((document) => (document as { id: string }).id));
    }, [documents]);

    const saveDocumentDetails = (formData: any, documentId: string) => {
        setUpdatingDoc(documentId);
        uploadDocumentStatusMutation({
            variables: {
                status: formData[`status-${documentId}`],
                dueBy: formData[`dueBy-${documentId}`],
                documentId,
            },
            refetchQueries: [
                { query: GET_CLIENT_DOCUMENTS, variables: { clientId: userId }, fetchPolicy: 'network-only' },
            ],
        })
            .then(() => {
                successMessage('User document updated successfully');
                setUpdatingDoc('');
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <Collapse
            bordered={false}
            accordion={false}
            activeKey={selectedPanels}
            onChange={(key) => setSelectedPanels(key)}
        >
            <span>
                <Row
                    className="min-width-mobile-320"
                    style={{
                        marginTop: '50px',
                        width: '80%',
                    }}
                >
                    <Col span={isAdmin ? 8 : 12} style={{ justifyContent: 'left', display: 'flex' }}>
                        <Text className="collapse-header-text">Name</Text>
                    </Col>
                    <Col span={4}>
                        <Text className="collapse-header-text">Status</Text>
                    </Col>
                    <Col span={4}>
                        <Text className="collapse-header-text">Received On</Text>
                    </Col>
                    <Col span={4}>
                        <Text className="collapse-header-text">Due By</Text>
                    </Col>
                    {isAdmin && (
                        <Col span={4}>
                            <Text className="collapse-header-text">Action</Text>
                        </Col>
                    )}
                </Row>
                <hr />
            </span>
            {documents?.map((item) => (
                <Panel
                    style={{
                        width: '85%',
                        margin: '20px',
                        alignItems: 'center',
                    }}
                    key={item.id}
                    showArrow={false}
                    header={
                        <Form onFinish={(values) => saveDocumentDetails(values, item.id)}>
                            <Row>
                                <Col
                                    className="panel-text align-self-center"
                                    span={isAdmin ? 8 : 12}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'left',
                                        alignItems: 'center',
                                        textAlign: 'left',
                                    }}
                                >
                                    <RightCircleOutlined
                                        style={{ fontSize: '32px', margin: '4px', fontWeight: 'bold' }}
                                    />
                                    {startCase(item.type.toLowerCase())}
                                </Col>
                                <Col className="panel-text align-self-center" span={4}>
                                    {isAdmin ? (
                                        <Form.Item name={`status-${item.id}`} initialValue={item.status}>
                                            <Select className="full-width" onClick={(e) => e.stopPropagation()}>
                                                <Option value={REQUIRED} key={REQUIRED}>
                                                    Required
                                                </Option>
                                                <Option value={COMPLETE} key={COMPLETE}>
                                                    Complete
                                                </Option>
                                            </Select>
                                        </Form.Item>
                                    ) : (
                                        item.status
                                    )}
                                </Col>
                                <Col className="panel-text align-self-center" span={4}>
                                    {item.receivedOn ? moment(item.receivedOn).format('ll') : '-'}
                                </Col>
                                <Col
                                    className="panel-text"
                                    span={4}
                                    onMouseDown={(e) => e.stopPropagation()}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    {item.dueBy ? (
                                        isAdmin ? (
                                            <Form.Item name={`dueBy-${item.id}`} initialValue={moment(item.dueBy)}>
                                                <DatePicker
                                                    onClick={(e) => e.stopPropagation()}
                                                    format="MM/DD/YYYY"
                                                    className="full-width"
                                                />
                                            </Form.Item>
                                        ) : (
                                            moment(item.dueBy).format('ll')
                                        )
                                    ) : (
                                        '-'
                                    )}
                                </Col>
                                {isAdmin && (
                                    <Col className="panel-text padding-x-md align-self-center" span={4}>
                                        <PrimaryButton
                                            title="Save"
                                            small
                                            loading={updatingDoc === item.id}
                                            htmlType="submit"
                                            onClick={(e) => e.stopPropagation()}
                                        />
                                    </Col>
                                )}
                            </Row>
                        </Form>
                    }
                >
                    <UploadDoc
                        textLeft="Upload"
                        textRight="Document"
                        documentId={item.id}
                        getUploading={getUploading}
                        clientId={userId}
                    />
                </Panel>
            ))}
        </Collapse>
    );
}

export default DocsOnboardCard;
