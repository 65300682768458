import React, { useEffect, useState, memo } from 'react';
import { Tabs, Typography, Row } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useQuery, useReactiveVar } from '@apollo/client';
import { MessagesCard } from '../components';
import { IMessages } from '../interfaces';
import { GET_CHANNELS } from '../graphql';
import { ARCHIVED, SENT } from '../services';
import { selectedMessage } from '../graphql/variables';

const { TabPane } = Tabs;
const { Title, Text } = Typography;

function MessageNotifications(): React.ReactElement {
    const selectedMessageVar: string = useReactiveVar(selectedMessage);
    const [messages, setMessages] = useState<IMessages[]>([]);
    const [messagesArchive, setMessagesArchive] = useState<IMessages[]>([]);
    const {
        loading: inboxLoading,
        data: inboxData,
        refetch: refetchInbox,
        startPolling,
        stopPolling,
    } = useQuery(GET_CHANNELS, {
        variables: { count: 50, status: SENT },
        fetchPolicy: 'network-only',
    });
    const {
        loading: archiveLoading,
        data: archiveData,
        refetch: refetchArchive,
        startPolling: startPollingArchive,
        stopPolling: stopPollingArchive,
    } = useQuery(GET_CHANNELS, {
        variables: { count: 50, status: ARCHIVED },
        fetchPolicy: 'network-only',
    });
    useEffect(() => {
        if (selectedMessageVar === '') {
            startPolling(5000);
            startPollingArchive(5000);
        } else {
            stopPolling();
            stopPollingArchive();
        }
        return () => {
            stopPolling();
            stopPollingArchive();
        };
    }, [selectedMessageVar]);

    useEffect(() => {
        if (inboxData?.getChannels) setMessages(inboxData.getChannels);
    }, [inboxData]);

    useEffect(() => {
        if (archiveData?.getChannels) setMessagesArchive(archiveData.getChannels);
    }, [archiveData]);

    return (
        <>
            <Row className="margin-bottom-lg space-between min-width-mobile-320">
                <Title level={3}>Message Notifications</Title>
                <Link to="compose-message">
                    <div className="margin-top-xs margin-right-lg font-big">
                        <Text>
                            <PlusCircleOutlined />
                            &nbsp;Compose
                        </Text>
                    </div>
                </Link>
            </Row>
            <div className="card-container">
                <Tabs defaultActiveKey="1" type="card">
                    <TabPane tab="Inbox" key="inbox">
                        <MessagesCard
                            withoutHeader
                            inbox
                            messages={messages}
                            loading={inboxLoading}
                            refetch={refetchInbox}
                        />
                    </TabPane>
                    <TabPane tab="Deleted" key="deleted">
                        <MessagesCard
                            withoutHeader
                            deleted
                            messages={messagesArchive}
                            loading={archiveLoading}
                            refetch={refetchArchive}
                        />
                    </TabPane>
                </Tabs>
            </div>
        </>
    );
}

export default memo(MessageNotifications);
