import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { DocumentsCard, MessagesCard, UsersTable } from '../components';
import { IDocuments, IMessages } from '../interfaces';
import { GET_CHANNELS, GET_CLIENT_DOCUMENTS } from '../graphql';
import { getToken } from '../services';

type DashboardProps = {
    isAdmin: boolean;
};

function Dashboard({ isAdmin }: DashboardProps): React.ReactElement {
    const [messages, setMessages] = useState<IMessages[]>([]);
    const [documents, setDocuments] = useState<IDocuments[]>([]);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { loading: channelsLoading, data: channelsData } = useQuery(GET_CHANNELS, {
        variables: { count: 50, status: 'SENT' },
        fetchPolicy: 'network-only',
    });
    const { loading: documentsLoading, data: documentsData } = useQuery(GET_CLIENT_DOCUMENTS, {
        variables: { clientId: getToken()?.user?.id },
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-argument
        if (channelsData?.getChannels) setMessages(channelsData.getChannels);
    }, [channelsData]);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-argument
        if (documentsData?.getClientDocuments) setDocuments(documentsData.getClientDocuments);
    }, [documentsData]);

    return (
        <div>
            <MessagesCard messages={messages} loading={channelsLoading} />
            <br />
            {isAdmin ? (
                <UsersTable roleId="CLIENT" title="User Accounts" />
            ) : (
                <DocumentsCard documents={documents} loading={documentsLoading} isAdmin={false} />
            )}
        </div>
    );
}

export default Dashboard;
