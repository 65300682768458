/* eslint-disable @typescript-eslint/no-unsafe-call, no-nested-ternary */
import React, { useState } from 'react';
import { Button, Card, Checkbox, Col, Row } from 'antd';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { startCase } from 'lodash';
import { IDocuments } from '../interfaces';
import NoData from './NoData';
import { COMPLETE, REQUIRED } from '../services';
import DocsOnboardCard from './DocsOnboardCard';
import { PrimaryButton } from './Buttons';

const { Grid } = Card;

interface DocumentsCardProps {
    documents: IDocuments[];
    loading?: boolean;
    isAdmin?: boolean;
    userId?: string;
}

function DocumentsCard(props: DocumentsCardProps): JSX.Element {
    const { documents, loading, isAdmin, userId } = props;
    const [update, setUpdate] = useState<boolean>(false);

    return (
        <Card
            title="Documents"
            className={`card ${update ? 'card-body-center' : ''}`}
            loading={loading}
            extra={
                update ? (
                    <PrimaryButton title="Done" small onClick={() => setUpdate(!update)} />
                ) : isAdmin ? (
                    <PrimaryButton title="Edit" small onClick={() => setUpdate(!update)} />
                ) : (
                    <Button style={{ color: 'white', fontSize: '20px' }} type="link" onClick={() => setUpdate(!update)}>
                        <UploadOutlined />
                    </Button>
                )
            }
        >
            {update ? (
                <DocsOnboardCard documents={documents} isAdmin={isAdmin} userId={userId} />
            ) : documents?.length > 0 ? (
                documents.map((document) => (
                    <Grid key={document.id} hoverable={false}>
                        <Row>
                            <Col span={18}>
                                <Checkbox className="full-width" checked={document.status === COMPLETE}>
                                    <Row className="documents-list display-block">
                                        {startCase(document.type.toLowerCase())}&nbsp;
                                        <span
                                            className={
                                                document.status === REQUIRED && !document.receivedOn ? 'red' : ''
                                            }
                                        >
                                            ({document.status})
                                        </span>
                                    </Row>
                                </Checkbox>
                            </Col>
                            <Col span={6} className="message-description">
                                <span className="float-right">
                                    {document.status === REQUIRED && !document.receivedOn ? (
                                        <span className="red">Due by: {moment(document.dueBy).format('ll')}</span>
                                    ) : (
                                        !!document.url && (
                                            <a href={document.url} download className="hoverable-icon black">
                                                <DownloadOutlined style={{ fontSize: '24px' }} />
                                            </a>
                                        )
                                    )}
                                </span>
                            </Col>
                        </Row>
                    </Grid>
                ))
            ) : (
                <NoData />
            )}
        </Card>
    );
}

export default DocumentsCard;
