import React, { useContext, useMemo } from 'react';
import { Layout, Row } from 'antd';
import { Link } from 'react-router-dom';
import { UserContext } from 'src/utils/UserContext';
import { deleteToken } from 'src/services';
import userIcon from '../assets/images/userIcon.svg';

type SiderProps = {
    isAdmin: boolean;
    setIsAuth?: (authenticated: boolean) => void;
};

type Links = {
    key: string;
    name: string;
    link: string;
};

type LinksMapper = {
    customerLinks: Links[];
    adminLinks: Links[];
};

function Sider({ isAdmin, setIsAuth }: SiderProps): JSX.Element {
    const userContext = useContext(UserContext);

    const handleClick = () => {
        setIsAuth?.(false);
        deleteToken();
    };

    const links: LinksMapper = {
        customerLinks: [
            {
                key: 'dashboard',
                name: 'Dashboard',
                link: '/',
            },
            {
                key: 'messages',
                name: 'Messages',
                link: 'messages',
            },
            {
                key: 'settings',
                name: 'Settings',
                link: 'settings',
            },
        ],
        adminLinks: [
            {
                key: 'dashboard',
                name: 'Dashboard',
                link: '/',
            },
            {
                key: 'messages',
                name: 'Messages',
                link: 'messages',
            },
            {
                key: 'accounts',
                name: 'Accounts',
                link: 'accounts',
            },
            {
                key: 'settings',
                name: 'Settings',
                link: 'settings',
            },
        ],
    };

    return (
        <Layout.Sider className="sider">
            <Row className="row name">
                <img
                    className="profile-photo-header"
                    src={userContext?.userState.photo || userIcon}
                    alt=""
                    style={{ height: '110px', width: '110px', borderRadius: '50%' }}
                />
            </Row>
            <Row className="row name">
                {userContext?.userState.firstName}&nbsp;{userContext?.userState.lastName}
            </Row>
            <Row className="row title">{userContext?.userState.roleId}</Row>
            <div className="margin-top-lg">
                {links[isAdmin ? 'adminLinks' : 'customerLinks'].map((link) => (
                    <Link to={link.link} key={link.key}>
                        <Row className="link row text">{link.name}</Row>
                    </Link>
                ))}
                <Link to="/" onClick={handleClick}>
                    <Row className="link row text show-on-mobile-only">logout</Row>
                </Link>
            </div>
        </Layout.Sider>
    );
}

export default Sider;
