import { gql } from '@apollo/client';

const SIGN_UP = gql`
    mutation signup($user: CreateUserInput!) {
        signup(user: $user) {
            user {
                id
                firstName
                lastName
                email
                phone
                roleId
            }
            token
        }
    }
`;

const LOGIN = gql`
    mutation login($credentials: CredentialsInput!) {
        login(credentials: $credentials) {
            user {
                id
                firstName
                lastName
                email
                phone
                roleId
                photo
            }
            token
        }
    }
`;

const SEND_MESSAGE = gql`
    mutation sendMessage($text: String!, $to: ID!) {
        sendMessage(text: $text, to: $to) {
            id
            text
            to {
                id
                firstName
                lastName
                email
                phone
                roleId
            }
            from {
                id
                firstName
                lastName
                email
                phone
                roleId
            }
            createdAt
            updatedAt
            status
        }
    }
`;

const ARCHIVE_MESSAGE = gql`
    mutation archiveMessage($id: ID!) {
        archiveMessage(id: $id) {
            success
        }
    }
`;
const UPDATE_CHANNEL = gql`
    mutation updateChannel($status: MESSAGE_STATUS!, $otherUserId: ID!) {
        updateChannel(status: $status, otherUserId: $otherUserId) {
            success
        }
    }
`;

const UPDATE_DOCUMENT = gql`
    mutation updateDocument($url: String!, $documentId: ID!, $clientId: ID!) {
        updateDocument(url: $url, documentId: $documentId, clientId: $clientId) {
            success
        }
    }
`;

const UPDATE_DOCUMENT_STATUS = gql`
    mutation updateDocumentStatus($dueBy: DateTime, $status: DOCUMENT_STATUS, $documentId: ID!) {
        updateDocumentStatus(dueBy: $dueBy, status: $status, documentId: $documentId) {
            success
        }
    }
`;

const UPDATE_USER = gql`
    mutation updateUser($update: UpdateUserInput!, $userId: String!) {
        updateUser(update: $update, userId: $userId) {
            success
        }
    }
`;

const DELETE_USER = gql`
    mutation deleteUser($userId: String!) {
        deleteUser(userId: $userId) {
            success
        }
    }
`;

const INVITE_USER = gql`
    mutation invite($email: String!, $role: USER_ROLE!) {
        invite(email: $email, role: $role) {
            success
        }
    }
`;

const RESET_PASSWORD = gql`
    mutation resetPassword($email: String!) {
        resetPassword(email: $email) {
            success
        }
    }
`;

export {
    SIGN_UP,
    LOGIN,
    SEND_MESSAGE,
    ARCHIVE_MESSAGE,
    UPDATE_DOCUMENT,
    UPDATE_DOCUMENT_STATUS,
    UPDATE_USER,
    DELETE_USER,
    INVITE_USER,
    UPDATE_CHANNEL,
    RESET_PASSWORD,
};
