import React, { useContext, useState } from 'react';
import { UPDATE_USER } from 'src/graphql';
import { useMutation } from '@apollo/client';
import { PrimaryButton } from 'src/components';
import { Card } from 'antd';
import { errorMessage, successMessage } from 'src/utils/Notifications';
import { UserContext } from 'src/utils/UserContext';
import { getToken, setToken } from 'src/services';
import { IAuth, IUser } from 'src/interfaces';
import UploadComponent from '../components/UploadComponent';

export type ProfileImageProps = {
    userId?: string | undefined;
    manageUser?: boolean;
    userDetailsPhotoSrc?: string;
};

function ProfileImage({ userId, manageUser, userDetailsPhotoSrc }: ProfileImageProps): JSX.Element {
    const userContext = useContext(UserContext);
    const [photo, setPhoto] = useState<string>('');
    const [updateProfilePic, { loading }] = useMutation(UPDATE_USER);

    const handleUpdate = () => {
        if (photo.length > 5) {
            updateProfilePic({
                variables: {
                    update: {
                        photo,
                    },
                    userId,
                },
            })
                .then(() => {
                    if (!manageUser) {
                        const data = getToken();
                        const newData: IAuth = {
                            ...data,
                            user: {
                                ...data.user,
                                photo,
                            } as IUser,
                        };
                        setToken(newData);
                        userContext.setUserState(newData.user as IUser);
                    }
                    successMessage('Profile picture updated successfully');
                })
                .catch(() => {
                    errorMessage('Failed to update profile picture');
                });
        } else {
            errorMessage('Upload a New Photo');
        }
    };

    return (
        <Card className="card padding-x-xl bordered-card" bordered>
            <UploadComponent text="Upload Photo" getUrl={setPhoto} userDetailsPhotoSrc={userDetailsPhotoSrc} />
            <span className="float-right">
                <PrimaryButton title="Save" onClick={handleUpdate} loading={loading} />
            </span>
        </Card>
    );
}

export default ProfileImage;
