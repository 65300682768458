import React from 'react';
import { Row, Typography } from 'antd';
import headerImage from '../assets/images/headerImage.svg';

const { Text } = Typography;

type AuthImageHeaderProps = {
    topText: string;
    pageTile: string;
    breadCrumb: string;
};

function AuthImageHeader({ topText, pageTile, breadCrumb }: AuthImageHeaderProps): React.ReactElement {
    return (
        <Row>
            <div
                style={{
                    height: '40vh',
                    width: '100%',
                    backgroundImage: `url(${headerImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                }}
            >
                <div className="padding-xl margin-left-lg no-padding-mobile" style={{ marginTop: '6em' }}>
                    <Row className="margin-top-l">
                        <Text
                            className="top-tex-auth-header"
                            style={{ color: 'white', fontSize: '24px', paddingLeft: '1.3em' }}
                        >
                            {topText}
                        </Text>
                    </Row>
                    <Row>
                        <Text
                            className="page-title-auth-header"
                            style={{
                                color: 'white',
                                fontWeight: 'bold',
                                fontSize: '64px',
                                borderLeft: 'solid 4px #EF976E',
                                paddingLeft: '0.4em',
                            }}
                        >
                            {pageTile}
                        </Text>
                    </Row>
                    <Row>
                        <Text style={{ color: 'white', fontSize: '24px', paddingLeft: '1.3em' }}>
                            Home {'>'} {breadCrumb}
                        </Text>
                    </Row>
                </div>
            </div>
            {/* <img */}
            {/*    src={headerImage} */}
            {/*    alt="" */}
            {/*    style={{ */}
            {/*        width: '100%', */}
            {/*    }} */}
            {/* /> */}
        </Row>
    );
}

export default AuthImageHeader;
