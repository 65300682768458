import React, { useContext } from 'react';
import { Layout, Col, Row, Menu } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from 'src/utils/UserContext';
import { deleteToken } from 'src/services';
import userIcon from '../assets/images/userIcon.svg';
import logo from '../assets/images/logo.svg';
import { PrimaryButton } from './Buttons';

// const MenuItem = Menu.Item;

function Header({
    isAuthenticated,
    setIsAuthenticated,
}: {
    isAuthenticated: boolean;
    setIsAuthenticated?: (authenticated: boolean) => void;
}): JSX.Element {
    // const [selectedMenuItem, setSelectedMenuItem] = useState<string>('home');
    const navigate = useNavigate();
    const userContext = useContext(UserContext);
    let srcProfilePhoto: string | undefined;
    if (isAuthenticated) srcProfilePhoto = userContext.userState.photo;
    return (
        <Layout.Header className="header">
            {isAuthenticated ? (
                <Row>
                    <Col
                        xs={6}
                        sm={4}
                        md={3}
                        lg={2}
                        xl={2}
                        className="align-self-center padding-top-sm padding-left-xl"
                    />
                    <Col xs={5} sm={5} md={6} lg={13} xl={14} />
                    <Col xs={4} sm={4} md={4} lg={6} xl={6} className="padding-top-xs">
                        <img src={logo} alt="logo" className="logo" />
                    </Col>
                    <Col xs={8} sm={8} md={4} lg={2} xl={2} className="align-self-center">
                        <div className="float-right logout-btn-header">
                            <PrimaryButton
                                title="Logout"
                                onClick={() => {
                                    setIsAuthenticated?.(false);
                                    deleteToken();
                                    navigate('/');
                                }}
                            />
                        </div>
                    </Col>
                </Row>
            ) : (
                <>
                    <Row className="align-self-center">
                        <Col span={6} className="padding-top-xs">
                            <img src={logo} alt="logo" className="logo" />
                        </Col>
                        <Col span={16} />
                        {/* Menus are not required on Webportal 
                        <Col span={14} className="align-self-center padding-right-md">
                            <div className="float-right">
                                <Menu
                                    mode="horizontal"
                                    selectedKeys={[selectedMenuItem]}
                                    onClick={({ key }) => setSelectedMenuItem(key)}
                                >
                                    <MenuItem key="home">Home</MenuItem>
                                    <MenuItem key="about">About</MenuItem>
                                    <MenuItem key="services">Services</MenuItem>
                                    <MenuItem key="portfolio">Portfolio</MenuItem>
                                    <MenuItem key="quote">Request A Quote</MenuItem>
                                    <MenuItem key="contact">Contact</MenuItem>
                                </Menu>
                            </div>
                        </Col> */}
                        <Col span={2} className="align-self-center">
                            <div className="float-right">
                                <Link to="/">
                                    <PrimaryButton title="Login" />
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </>
            )}
        </Layout.Header>
    );
}

export default Header;
