import React from 'react';
import { Layout } from 'antd';
import { Route, Routes } from 'react-router-dom';
import { UserContextProvider } from 'src/utils/UserContext';
import Sider from 'src/components/Sider';
import { Header } from '../components';
import Dashboard from './Dashboard';
import MessageNotifications from './MessageNotifications';
import ComposeMessage from './ComposeMessage';
import Accounts from './Accounts';
import Profile from './Profile';
import Settings from './Settings';

const { Content } = Layout;

type MainLayoutProps = {
    setIsAuthenticated: (authenticated: boolean) => void;
    isAdmin: boolean;
};

function MainLayout({ setIsAuthenticated, isAdmin }: MainLayoutProps): React.ReactElement {
    return (
        <UserContextProvider>
            <Layout>
                <Header isAuthenticated setIsAuthenticated={setIsAuthenticated} />
                <Layout className="content-layout">
                    <Sider isAdmin={isAdmin} setIsAuth={setIsAuthenticated} />
                    <Content className="content">
                        <div className="restricted-width">
                            <Routes>
                                <Route key="home" path="/*" element={<Dashboard isAdmin={isAdmin} />} />
                                <Route key="messages" path="messages" element={<MessageNotifications />} />
                                <Route
                                    key="compose-message"
                                    path="messages/compose-message"
                                    element={<ComposeMessage />}
                                />
                                <Route key="settings" path="settings" element={<Settings isAdmin={isAdmin} />} />
                                <Route key="accounts" path="accounts" element={<Accounts />} />
                                <Route key="profile" path="profile/:userId" element={<Profile isAdmin={isAdmin} />} />
                                <Route
                                    key="profile-accounts"
                                    path="accounts/profile/:userId"
                                    element={<Profile isAdmin={isAdmin} />}
                                />
                            </Routes>
                        </div>
                    </Content>
                </Layout>
            </Layout>
        </UserContextProvider>
    );
}

export default MainLayout;
