import React from 'react';
import { Form, Input, Card } from 'antd';
import { UPDATE_USER } from 'src/graphql';
import { useMutation } from '@apollo/client';
import { errorMessage, successMessage } from 'src/utils/Notifications';
import { PrimaryButton } from '../components';

const { Password } = Input;
const FormItem = Form.Item;

export type UpdatePasswordProps = {
    userId?: string;
};

export type IFormDataPassword = {
    password: string;
};

function UpdatePassword({ userId }: UpdatePasswordProps): JSX.Element {
    const [updatePassword, { loading }] = useMutation(UPDATE_USER);

    const handleOnFinish = (formData: IFormDataPassword) => {
        const { password } = formData;
        updatePassword({
            variables: {
                update: {
                    password,
                },
                userId,
            },
        })
            .then(() => {
                successMessage('Password updated successfully');
            })
            .catch(() => {
                errorMessage('Failed to update password');
            });
    };

    return (
        <div className="bordered-card">
            <Card className="card padding-x-xl">
                <Form className="padding-x-xl padding-top-lg" onFinish={handleOnFinish}>
                    <FormItem
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                        hasFeedback
                    >
                        <Password placeholder="New Password" />
                    </FormItem>
                    <FormItem
                        name="confirmPassword"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error('The two passwords that you entered do not match!'),
                                    );
                                },
                            }),
                        ]}
                    >
                        <Password placeholder="Confirm Password" />
                    </FormItem>
                    <div className="float-right margin-bottom-lg">
                        <PrimaryButton loading={loading} title="Update" htmlType="submit" />
                    </div>
                </Form>
            </Card>
        </div>
    );
}

export default UpdatePassword;
