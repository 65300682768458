/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo } from 'react';
import { Card, Col, Row } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useMutation, useReactiveVar } from '@apollo/client';
import { getID } from 'src/services';
import { faMinusSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import userIcon from '../assets/images/userIcon.svg';
import { IMessages } from '../interfaces';
import { PrimaryButton } from './Buttons';
import { successMessage } from '../utils/Notifications';
import { UPDATE_CHANNEL, selectedMessage } from '../graphql';
import NoData from './NoData';
import MessageList from './MessageList';

const { Grid } = Card;

interface MessagesCardProps {
    messages: IMessages[];
    loading?: boolean;
    withoutHeader?: boolean;
    inbox?: boolean;
    deleted?: boolean;
    refetch?: () => void;
}

function MessagesCard(props: MessagesCardProps): JSX.Element {
    const selectedMessageVar: string = useReactiveVar(selectedMessage);
    const { messages, loading, withoutHeader, inbox, deleted, refetch } = props;
    const [updateChannel, { loading: archiving }] = useMutation(UPDATE_CHANNEL);

    const handleArchiveMessage = (otherID: string) => {
        updateChannel({
            variables: { status: 'ARCHIVED', otherUserId: otherID },
        })
            .then(() => {
                successMessage('Message archived successfully');
                refetch?.();
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleRestoreMessage = (otherID: string) => {
        updateChannel({
            variables: { status: 'SENT', otherUserId: otherID },
        })
            .then(() => {
                successMessage('Message Restore successfully');
                refetch?.();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const checkSenderIfSelf = (message: IMessages) => {
        const currentUser = getID();
        if (message?.from?.id === currentUser) {
            return true;
        }
        return false;
    };
    const handleMinimize = () => {
        selectedMessage('');
    };
    return (
        <Card loading={loading} title={!withoutHeader && 'Message Notifications'} className="card">
            {messages.length > 0 ? (
                messages?.map((message) => {
                    return (
                        <Grid>
                            {!(selectedMessageVar === message.id) ? (
                                <Row className="selectable">
                                    <Col span={2} className="align-self-center">
                                        {checkSenderIfSelf(message) && message.to?.photo && (
                                            <img src={message.to?.photo} alt="" className="large-icon" />
                                        )}
                                        {!checkSenderIfSelf(message) && message.from?.photo && (
                                            <img src={message.from?.photo} alt="" className="large-icon " />
                                        )}
                                        {checkSenderIfSelf(message) && !message.to?.photo && (
                                            <img src={userIcon} alt="" className="large-icon" />
                                        )}
                                    </Col>
                                    <Col
                                        span={20}
                                        style={{ maxWidth: '80%' }}
                                        onClick={() => selectedMessage(message.id)}
                                    >
                                        {checkSenderIfSelf(message) &&
                                        (message.to?.firstName || message.to?.lastName) ? (
                                            <Row className="message-title">
                                                {message.to?.firstName} {message.to?.lastName}{' '}
                                            </Row>
                                        ) : (
                                            <Row className="message-title">
                                                {message.from?.firstName} {message.from?.lastName}{' '}
                                            </Row>
                                        )}
                                        <Row className="message-description">{message.text}</Row>
                                    </Col>
                                    {inbox && (
                                        <Col span={2} className="align-right align-self-center">
                                            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                                            {checkSenderIfSelf(message) && message.to.id && (
                                                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                                                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                                                <span
                                                    className="selectable"
                                                    onClick={() => handleArchiveMessage(message.to.id)}
                                                >
                                                    <CloseCircleOutlined className="cross-icon" spin={archiving} />
                                                </span>
                                            )}
                                        </Col>
                                    )}
                                    {deleted && (
                                        <Col span={2} className="align-right align-self-center">
                                            {checkSenderIfSelf(message) && message.to.id && (
                                                <PrimaryButton
                                                    title="Restore"
                                                    small
                                                    onClick={() => handleRestoreMessage(message.to.id)}
                                                />
                                            )}
                                        </Col>
                                    )}
                                </Row>
                            ) : (
                                <>
                                    <Row className="selectable chat-header" style={{ marginBottom: '12px' }}>
                                        <Col span={2} className="align-self-center">
                                            {checkSenderIfSelf(message) && message.to?.photo && (
                                                <img src={message.to?.photo} alt="" className="large-icon " />
                                            )}
                                            {!checkSenderIfSelf(message) && message.from?.photo && (
                                                <img src={message.from?.photo} alt="" className="large-icon " />
                                            )}
                                            {checkSenderIfSelf(message) && !message.to?.photo && (
                                                <img src={userIcon} alt="" className="large-icon " />
                                            )}
                                        </Col>
                                        <Col
                                            span={20}
                                            style={{ maxWidth: '80%' }}
                                            onClick={() => selectedMessage(message.id)}
                                        >
                                            {checkSenderIfSelf(message) &&
                                            (message.to?.firstName || message.to?.lastName) ? (
                                                <Row className="message-title">
                                                    {message.to?.firstName} {message.to?.lastName}{' '}
                                                </Row>
                                            ) : (
                                                <Row className="message-title">
                                                    {message.from?.firstName} {message.from?.lastName}{' '}
                                                </Row>
                                            )}
                                            <Row className="message-description">{message.text}</Row>
                                        </Col>
                                        <Col className="cover-rest-width">
                                            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                                            <div className="minimize-icon h-inherit" onClick={() => handleMinimize()}>
                                                <FontAwesomeIcon icon={faMinusSquare} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={{ maxHeight: '80vh', position: 'relative' }}>
                                        {checkSenderIfSelf(message) ? (
                                            <MessageList
                                                channelId={message?.id}
                                                othersId={message?.to.id}
                                                othersUserPhoto={message?.to.photo}
                                                othersFirstName={message?.to.firstName}
                                                othersLastname={message?.to.lastName}
                                            />
                                        ) : (
                                            <MessageList
                                                channelId={message?.id}
                                                othersId={message?.from.id}
                                                othersUserPhoto={message?.from.photo}
                                                othersFirstName={message?.to.firstName}
                                                othersLastname={message?.to.lastName}
                                            />
                                        )}
                                    </Row>
                                </>
                            )}
                        </Grid>
                    );
                })
            ) : (
                <NoData />
            )}
        </Card>
    );
}

export default memo(MessagesCard);
