/* eslint-disable react/prop-types,max-len,no-nested-ternary,no-param-reassign,no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { Upload, Typography, Card } from 'antd';
import { useMutation } from '@apollo/client';
import { CheckCircleTwoTone } from '@ant-design/icons';
import uploadIcon from '../assets/images/uploadIcon.svg';
import { GET_CLIENT_DOCUMENTS, UPDATE_DOCUMENT } from '../graphql';
import { successMessage } from '../utils/Notifications';
import { getToken } from '../services';

const { Dragger } = Upload;
const { Text } = Typography;

interface UploadProps {
    textLeft?: string;
    textRight?: string;
    getUploading?: (uploading: boolean) => void;
    error?: string;
    documentId: string;
    clientId?: string;
}

export default function UploadDoc(props: UploadProps): JSX.Element {
    const { textLeft, textRight, error, documentId, getUploading, clientId } = props;

    const { user } = getToken();
    const [file, setFile] = useState<{ name: string } | null>(null);
    const [uploadDocumentMutation, { loading: uploadingDoc }] = useMutation(UPDATE_DOCUMENT);

    const handleUpload = (url: string) => {
        uploadDocumentMutation({
            variables: {
                url,
                clientId: clientId || user?.id,
                documentId,
            },
            refetchQueries: [
                { query: GET_CLIENT_DOCUMENTS, variables: { clientId: user?.id }, fetchPolicy: 'network-only' },
            ],
        })
            .then(() => {
                successMessage('Document uploaded successfully');
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const uploadProps = {
        name: 'file',
        multiple: false,
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        action: `${process.env.REACT_APP_UPLOAD_URL}`,
        onSuccess: (res: string[], uploadedFile: { name: string }) => {
            setFile(uploadedFile);
            handleUpload(res[0]);
        },
        showUploadList: false,
    };

    useEffect(() => {
        getUploading?.(uploadingDoc);
    }, [uploadingDoc, getUploading]);

    return (
        <div className="margin-y-md">
            <span>
                <Card
                    hoverable
                    style={{
                        borderColor: '#BEBEBE',
                        width: '75%',
                    }}
                >
                    <Dragger {...uploadProps}>
                        <span
                            style={{
                                fontSize: '14px',
                                textAlign: 'center',
                                fontWeight: 'bold',
                                margin: '8px',
                            }}
                        >
                            {textLeft}
                        </span>
                        <img src={uploadIcon} alt="upload" style={{ height: '50px' }} />
                        <span
                            style={{
                                fontSize: '14px',
                                textAlign: 'center',
                                fontWeight: 'bold',
                                margin: '8px',
                            }}
                        >
                            {textRight}
                        </span>
                    </Dragger>
                </Card>
            </span>
            {file && Object.keys(file).length > 0 && (
                <div className="margin-y-md" style={{ color: 'gray', display: 'flex', justifyContent: 'center' }}>
                    {file.name} uploaded successfully&nbsp;
                    <CheckCircleTwoTone style={{ marginTop: '4px' }} twoToneColor="#EF976E" />
                </div>
            )}
            {!!error && <Text type="danger">{error}</Text>}
        </div>
    );
}
